//
// text.scss
//

// Weight

.fw-semibold {
  font-weight: 500 !important;
}
.fw-normal {
  font-weight: $font-weight-normal !important;
}
.fw-600 {
  font-weight: 600 !important;
}
.fw-bold {
  font-weight: $font-weight-bold !important;
}

.text-body {
  color: #{map-get($grays, '700')} !important;
}

// Dark mode
body[data-layout-color='dark'] {
  @each $color, $value in $dark-theme-colors {
    .text-#{$color} {
      color: $value !important;
    }
  }
  .text-body {
    color: #{map-get($dark-grays, '500')} !important;
  }
}

.text-truncate-one-line {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.text-card-title {
  font-size: 18px;
  font-weight: 600;
  line-height: 22.5px;
  text-align: left;
  color: black;
}

.text-card-subtitle {
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
  text-align: left;
  color: black;
}

.text-card-subtitle-2 {
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
  text-align: left;
  color: black;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
