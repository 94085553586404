.datepicker-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;

  .datepicker-select {
    padding: 8px 0;
    margin: 0 5px;
    border: 1px solid #ced4da;
    border-radius: 4px;
    background-color: white;
    width: 50%;
    cursor: pointer;

    &:focus {
      outline: none;
      border-color: #80bdff;
      box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
    }

    // Style the scrollbar
    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #007bff;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
  }
}

.custom-date {
  position: relative;
  display: flex;
  align-items: center;
}
