//
// general.scss
//

html {
  position: relative;
  min-height: 100%;
}

body {
  overflow-x: hidden;
}

@include media-breakpoint-up(sm) {
  .main-content {
    padding: 24px;
  }
}

// Fix event bubbling on iOS devices
@supports (-webkit-overflow-scrolling: touch) {
  body {
    cursor: pointer;
  }
}

.additional-bg {
  background-color: #fefefe !important;
}

.acordion-bg {
  background-color: #f7f7f7 !important;
}
