.card-challenges-overview,
.card-challenges {
  position: relative;
  overflow: hidden;

  .media-challenges {
    position: relative;

    &.is-active {
      img {
        filter: blur(2px);
        -webkit-filter: blur(2px);
        transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
        -webkit-transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
        -moz-transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
        -ms-transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
        -o-transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
        transition-delay: 0.1s;
      }
    }
  }

  .image-challenges {
    height: 150px;
  }

  .tittle-challenges {
    font-size: 16px;
    font-weight: 700;
    color: $neutral-7;
    line-height: 155%;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .level-challenges,
  .details-challenges {
    display: flex;
    align-items: center;
    margin: 0;
    gap: 4px;

    span {
      font-size: 14px;
      font-weight: 400;
      color: $neutral-4;
    }
  }

  .status-challenges {
    position: absolute;
    left: 10px;
    bottom: 10px;
    display: flex;
  }
}

.challenge-select-item-header .total-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $white;
  color: $primary-blue;
  border-radius: 50%;
  font-size: 13px;
  font-weight: 700;
  height: 24px;
  width: 24px;
}

.actions-challenges {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 14px;
  z-index: 2;
}

.action-button-challenges {
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background-color: transparent;
}

.price-label-challenges {
  background-color: $primary-red;
  color: $white;
  font-size: 13px;
  font-weight: 700;
  padding: 3.5px 16px;
  border-radius: 0;
}

.actions-drawer-challenges {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $white;
  border-radius: 4px 4px 0 0;
  -webkit-border-radius: 4px 4px 0 0;
  -moz-border-radius: 4px 4px 0 0;
  -ms-border-radius: 4px 4px 0 0;
  -o-border-radius: 4px 4px 0 0;
  padding: 12px 12px 62px 12px;
  opacity: 0;
  transform: translateY(100%);
  -webkit-transform: translateY(100%);
  -moz-transform: translateY(100%);
  -ms-transform: translateY(100%);
  -o-transform: translateY(100%);
  transition: 0.4s cubic-bezier(0.075, 0.82, 0.16, 1.1);
  -webkit-transition: 0.4s cubic-bezier(0.075, 0.82, 0.16, 1.1);
  -moz-transition: 0.4s cubic-bezier(0.075, 0.82, 0.16, 1.1);
  -ms-transition: 0.4s cubic-bezier(0.075, 0.82, 0.16, 1.1);
  -o-transition: 0.4s cubic-bezier(0.075, 0.82, 0.16, 1.1);
  transition-delay: 0.1s;
  visibility: hidden;

  &.is-active {
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    opacity: 1;
    z-index: 1;
    visibility: visible;
  }
}

.actions-drawer-item-challenges {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 6px 12px;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  transition: background-color 0.2s ease;
  -webkit-transition: background-color 0.2s ease;
  -moz-transition: background-color 0.2s ease;
  -ms-transition: background-color 0.2s ease;
  -o-transition: background-color 0.2s ease;
  cursor: pointer;

  &__copyable-item {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  span {
    font-size: 13px;
    font-weight: 600;
  }

  &:hover {
    background-color: $neutral-2;
  }
}

.status-card {
  padding: 4px 24px;
  display: flex;
  align-items: center;
  gap: 4px;

  .status-title {
    font-size: 13px;
  }

  &--sm {
    padding: 4px 8px;
  }
}

@media (max-width: 1920px), (max-width: 1440px) {
  .filter-bar {
    overflow-y: scroll;
  }

  .search-filter {
    margin-left: 14px;
  }

  .button-new {
    display: flex;
    justify-content: end;
  }
}

.input-custom {
  border: none;
  padding: 2px;

  &:focus {
    outline: none;
  }

  &:disabled {
    background-color: white;
  }
}

.icon-input {
  color: $primary-blue;
  font-size: 20px;
  background-color: white;
  height: 20px;
  width: 20px;
  text-align: center;
  line-height: 20px;
  border-radius: 100%;
  display: inline-block;
}

.img-list {
  height: 150px;
  width: 100%;
  object-fit: cover;
}

.filter-wrapper {
  position: relative;

  button {
    width: 100%;
  }
}

.table-filter-card-wrapper {
  position: absolute;
  margin-top: 20px;
  width: 300px;
  border: 1px solid #dddce1;
  border-radius: 5px;
  background-color: white;
  padding: 12px 0;
  visibility: hidden;
  opacity: 0;
  transition: 0.1s ease-in-out;
  user-select: none;
  z-index: 1;
  -webkit-transition: 0.1s ease-in-out;
  -moz-transition: 0.1s ease-in-out;
  -ms-transition: 0.1s ease-in-out;
  -o-transition: 0.1s ease-in-out;
  transform: translateY(5px);
  -webkit-transform: translateY(5px);
  -moz-transform: translateY(5px);
  -ms-transform: translateY(5px);
  -o-transform: translateY(5px);

  &.show {
    visibility: unset;
    opacity: 1;
    user-select: all;
    z-index: 4;
    transform: unset;
    -webkit-transform: unset;
    -moz-transform: unset;
    -ms-transform: unset;
    -o-transform: unset;
  }

  .form-label {
    margin: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    user-select: none;
    cursor: pointer;
  }
}
