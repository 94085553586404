.media-gallery {
  position: relative;
  border-radius: 8px;
  overflow: hidden;

  &--inside-card {
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;

    .media-gallery__label {
      padding: 4px 10px;
      font-size: 13px;
    }

    .media-gallery__page-info {
      top: 12px;
      right: 12px;
      padding: 2px 8px;
      font-size: 10px;
      gap: 1px;
      border-radius: 9px;
      -webkit-border-radius: 9px;
      -moz-border-radius: 9px;
      -ms-border-radius: 9px;
      -o-border-radius: 9px;
    }
  }

  &__label {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 6px;
    top: 0;
    left: 0;
    padding: 4px 24px;
    background-color: $black;
    color: $white;
    font-size: 18px;
    z-index: 2;

    .icon {
      font-size: 24px;
    }
  }

  &__page-info {
    position: absolute;
    top: 25px;
    right: 25px;
    background-color: rgba($black, 0.65);
    padding: 3px 12px;
    display: flex;
    gap: 4px;
    color: $white;
    font-size: 18px;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
    z-index: 2;
  }
}
