//
// preloader.scss
//

.preloader {
  &.centered {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.cover-page {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: -$topbar-height;
  }
}

@keyframes bouncing-loader {
  to {
    opacity: 0.1;
    transform: translate3d(0, -16px, 0);
  }
}

.bouncing-loader {
  display: flex;
  justify-content: center;

  > div {
    width: 15px;
    height: 15px;
    margin: 32px 4px;
    background: $primary;
    border-radius: 50%;
    animation: bouncing-loader 0.6s infinite alternate;

    &:nth-child(2) {
      animation-delay: 0.2s;
      background: $primary-red;
    }

    &:nth-child(3) {
      animation-delay: 0.4s;
      background: $primary-yellow;
    }
  }

  &.size-md {
    > div {
      width: 15px;
      height: 15px;
      margin: 32px 4px;
    }
  }

  &.size-lg {
    > div {
      width: 25px;
      height: 25px;
      margin: 36px 8px;
    }
  }
}
