//
// menu.scss
//

// Logo
.logo {
  display: block;
  line-height: $topbar-height;
  width: $leftbar-width;
  position: fixed;
  top: 0;

  span.logo-lg {
    display: block;
  }

  span.logo-sm {
    display: none;
  }

  &.logo-light {
    display: $logo-light-display;
  }

  &.logo-dark {
    display: $logo-dark-display;
  }
}

.content-page {
  margin-left: $leftbar-width;
  padding: 82px 0 0 0 !important;
  min-height: 100vh;
  background-color: #fff;
  overflow: hidden;
}

.leftside-menu {
  width: $leftbar-width;
  z-index: 10;
  background: #f9f9f9 !important;
  position: fixed;
  top: 0;
  bottom: 0;
  padding-top: $topbar-height;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 1px;
}

// Side-nav
.side-nav {
  padding-left: 0;
  list-style-type: none;

  ul {
    list-style-type: none;
  }

  .side-nav-link {
    margin: 0 24px 12px 24px;
    color: $secondary;
    display: flex;
    align-items: center;
    padding: 12px 0;
    position: relative;
    list-style: none;
    font-weight: 400;
    font-size: 15px;
    line-height: 135%;

    &:hover,
    &:focus,
    &:active {
      text-decoration: none;
    }

    i {
      display: inline-block;
      line-height: 1.0625rem;
      margin: 0 10px;
      font-size: 1.1rem;
      vertical-align: middle;
      width: 20px;
    }
  }

  .menu-arrow {
    transition: transform 0.15s;
    position: absolute;
    right: 30px;
    display: inline-block;
    font-family: 'Material Design Icons';
    line-height: 1.5rem;
    font-size: 1.1rem;
    transform: translate(0, 0);

    &:before {
      content: '\F0142';
    }
  }

  .badge {
    margin-top: 3px;
  }

  .side-nav-item {
    > a[aria-expanded='true'] {
      > span.menu-arrow {
        transform: rotate(90deg);
      }
    }

    &.menuitem-active {
      > a:not(.collapsed) {
        > span.menu-arrow {
          transform: rotate(90deg);
        }
      }
    }
  }

  .side-nav-title {
    padding: 12px 30px;
    letter-spacing: 0.05em;
    pointer-events: none;
    cursor: default;
    text-transform: uppercase;
    color: #a3a3a3;
    font-size: 15px;
    font-weight: 700;
    line-height: 155%;
  }

  .menuitem-active {
    > a {
      color: $black !important;
    }

    &:hover > a {
      color: $white !important;
    }

    .side-nav-link {
      background-color: $white;
      border-radius: 4px;
      align-items: center;
    }

    .active-bar {
      position: absolute;
      top: 50%;
      left: 0;
      display: block;
      background-color: $primary-blue;
      border-radius: 0 12px 12px 0;
      width: 3px;
      height: 24px;
      transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
    }
  }
}

*[dir='ltr'] .side-nav .side-nav-item .menu-arrow:before {
  content: '\F0141' !important;
}

.side-nav-second-level,
.side-nav-third-level,
.side-nav-forth-level {
  padding-left: 0;

  li {
    a,
    .side-nav-link {
      padding: 8px 30px 8px 65px;
      color: $black;
      display: block;
      position: relative;
      transition: all 0.4s;
      font-size: 0.89rem;

      &:focus,
      &:hover {
        color: gray;
      }

      .menu-arrow {
        line-height: 1.3rem;
      }
    }

    &.active > a {
      color: $black;
    }
  }
}

.side-nav-third-level li a,
.side-nav-third-level .side-nav-link {
  padding: 8px 30px 8px 80px;
}

.side-nav-forth-level li a,
.side-nav-forth-level .side-nav-link {
  padding: 8px 30px 8px 100px;
}

body[data-leftbar-compact-mode='fixed']:not(.authentication-bg) {
  .side-nav .side-nav-item:hover .side-nav-link {
    color: $black !important;
  }
}

// Enlarge menu
body[data-leftbar-compact-mode='condensed']:not(.authentication-bg) {
  .side-nav .side-nav-item .side-nav-link {
    background-color: transparent;
    margin: 0;
    border-radius: 0;

    span {
      color: $white;
    }
  }

  .side-nav .side-nav-item:hover .side-nav-link {
    color: $white;
  }

  .side-nav .side-nav-item:hover {
    .menu-item-link-label {
      display: flex !important;
      align-items: center;
      justify-content: flex-start;
      text-align: start;
      margin: auto;
      height: 47px;
      min-width: 150px;
      color: $white !important;
      background: rgba($primary-blue, 0.9);
      margin-left: 10px;
    }
  }

  .wrapper {
    .side-nav-link {
      margin-left: 0;
    }
    .leftside-menu {
      position: fixed;
      width: 70px;
      z-index: 5;
      padding-top: $topbar-height;

      .simplebar-mask,
      .simplebar-content-wrapper {
        overflow: visible !important;
      }

      .simplebar-scrollbar {
        display: none !important;
      }

      .logo {
        width: 70px;
        z-index: 1;
        background: $bg-leftbar-gradient;
      }
    }

    .content-page {
      margin-left: 70px;
    }

    .navbar-custom,
    .footer {
      left: 70px;
    }
  }

  .side-nav .side-nav-title,
  .side-nav .badge,
  .side-nav .menu-arrow {
    display: none !important;
  }

  .side-nav-item .side-nav-link {
    min-height: 26px;
    padding: 15px 20px;
    transition: all 0.1s;
    -webkit-transition: all 0.1s;
    -moz-transition: all 0.1s;
    -ms-transition: all 0.1s;
    -o-transition: all 0.1s;

    &:hover,
    &:active,
    &:focus {
      color: gray;
    }

    i {
      font-size: 1.125rem;
      margin-right: 20px;
      margin-left: 6px;
    }

    span {
      display: none;
      font-size: 15px;
    }
  }

  .side-nav-item:hover .side-nav-link {
    max-height: 80px;
    color: $neutral-6;
    background: rgba($primary-blue, 0.9);
    text-wrap: nowrap;

    span {
      position: absolute;
      top: 50%;
      left: 60px;
      display: inline-block;
      transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      -moz-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      -o-transform: translateY(-50%);
      padding-right: 20px;
    }
  }

  .side-nav-item:hover > ul {
    display: block !important;
    left: 70px;
    position: absolute;
    width: 230px;
    height: auto !important;
    padding: 5px 0;
    z-index: 9999;
    background: $bg-leftbar-gradient;
    box-shadow: $box-shadow;

    a {
      padding: 8px 20px;
      position: relative;
      width: 190px;

      &:hover {
        color: gray;
      }
    }
  }

  .side-nav-item:hover > .collapse,
  .side-nav-item:hover > .collapsing {
    display: block !important;
    transition: none !important;

    > ul {
      left: 70px;
      position: absolute;
      width: 190px;
      background: $bg-leftbar-gradient;
      box-shadow: $box-shadow;

      a {
        padding: 8px 20px;
        position: relative;
        width: 190px;

        &:hover {
          color: $black;
        }
      }

      li:hover > .collapse {
        display: block !important;
        height: auto !important;
        transition: none !important;

        > ul {
          left: 190px;
          top: 0;
          position: absolute;
          width: 190px;
        }
      }
    }
  }

  .logo span.logo-lg {
    display: none;
  }

  .logo span.logo-sm {
    display: block;
    line-height: 70px;
    color: $primary;
  }
}

// Responsive styles
@include media-breakpoint-down(md) {
  body {
    overflow-x: hidden;
  }

  .leftside-menu {
    display: none;
    z-index: 10 !important;
  }

  .sidebar-enable .leftside-menu {
    display: block;
  }

  .content-page {
    margin-left: 0 !important;
  }

  .logo span.logo-lg {
    display: block;
  }

  .logo span.logo-sm {
    display: none;
  }
}
