.category-label {
  display: flex;
  align-items: center;
  gap: 8px;

  .name {
    font-weight: 700;
    font-size: 18px;
  }
}
