:root {
  --fc-border-color: white;
}

.fc {
  .fc-toolbar-chunk:last-child {
    display: flex;
    gap: 6px;
    align-items: center;
  }

  .fc-button .fc-icon {
    font-size: 1.4em;
  }

  .fc-toolbar-title {
    font-size: 1.125em;
    color: #404040;
    font-weight: 600;
    margin: 0 !important;
    padding-top: 0.15em;
  }

  .fc-scroller-harness {
    padding-bottom: 2.5em;
  }

  .fc-col-header-cell-cushion {
    color: $black;
    opacity: 50%;
    font-weight: 600;
  }

  .fc-day-today {
    background: transparent !important;

    .fc-daygrid-day-number {
      background-color: $primary-red;
      color: $white !important;
    }
  }

  .fc-daygrid-event {
    margin-top: 4px;
  }

  .fc-event {
    cursor: pointer;
  }

  .fc-h-event {
    background-color: $neutral-2;
    border-color: $neutral-2;
    border-radius: 4px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;

    .fc-event-title {
      font-size: 0.9rem;
      color: $black;
      padding: 0.25em 0.5em;
    }
  }

  .fc-daygrid-day-frame {
    min-height: 140px !important;
  }

  .fc-daygrid-day-top {
    margin-top: 4px;
    justify-content: center;

    .fc-daygrid-day-number {
      font-size: 1rem;
      font-weight: 400;
      color: $black;
      width: 35px;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      -webkit-border-radius: 50%;
      -moz-border-radius: 50%;
      -ms-border-radius: 50%;
      -o-border-radius: 50%;
    }
  }

  .fc-popover {
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
    z-index: 10;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
  }

  .fc-popover-header {
    background-color: $primary-blue;
    padding: 5px;
    color: $white;
    border-radius: 8px 8px 0 0;
    -webkit-border-radius: 8px 8px 0 0;
    -moz-border-radius: 8px 8px 0 0;
    -ms-border-radius: 8px 8px 0 0;
    -o-border-radius: 8px 8px 0 0;

    .fc-popover-title {
      font-size: 0.8rem;
      font-weight: 600;
    }
  }

  .fc-popover-body {
    background-color: $white;

    .fc-event-title {
      font-size: 0.8rem;
    }
  }

  .fc-scroller-harness {
    padding-bottom: 0;
  }
}

@media screen and (max-width: 768px) {
  .fc {
    .fc-daygrid-day-top {
      .fc-daygrid-day-number {
        font-size: 1rem;
      }
    }

    .fc-h-event {
      .fc-event-title {
        font-size: 0.9rem;
      }
    }
  }
}
