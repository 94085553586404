.image-thumbnail {
  object-fit: cover;
  border: solid;
  border-width: 0.4px;
  border-color: white;

  &.img-error {
    object-fit: cover;
  }
}

.col-with-image {
  display: flex;
  align-items: center;

  img {
    margin-right: 0.6rem;
  }
}
