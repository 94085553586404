.profile-box {
  display: inline-block;
  align-items: center;

  .profile-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 60px;
    background-color: $primary;
    color: white;
  }
}
