.advanced-table {
  td {
    .col-with-image {
      display: flex;
      align-items: center;

      img {
        margin-right: 0.6rem;
      }
    }
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
    font-weight: 400;
    color: $black;
  }

  width: 100%;

  tr {
    width: 100%;
  }
}

@include media-breakpoint-down(xl) {
  .advanced-table {
    th {
      display: flex;
      align-items: center;
      background-color: transparent !important;

      .table-th {
        flex: 1;

        &:not(.sortable) {
          display: none;
        }
      }
    }

    td {
      &::before {
        content: attr(data-label);
        font-weight: 400;
      }

      .action-icon {
        padding: 0;
        font-size: unset;
        line-height: unset;

        .mdi-24px::before {
          font-size: 32px;
          line-height: 32px;
        }

        .mdi-25px::before {
          font-size: 33px;
          line-height: 33px;
        }

        + .action-icon {
          padding-left: 8px;
        }
      }

      .col-with-multi-value {
        display: block;
      }
    }
  }
}

@include media-breakpoint-between(lg, xl) {
  .advanced-table {
    thead tr {
      margin-bottom: 1.2rem;
    }

    tbody {
      display: inline-flex;
      flex-wrap: wrap;
      width: 100%;

      tr {
        width: 33.3333%;
      }

      td {
        display: block;
        padding: 0.5rem 0.95rem;

        &::before {
          display: block;
          margin-bottom: 5px;
        }
      }
    }
  }
}

@include media-breakpoint-between(sm, lg) {
  .advanced-table {
    thead tr {
      display: block;
      margin-bottom: 1.2rem;
    }

    tbody {
      display: inline-flex;
      flex-wrap: wrap;
      width: 100%;

      tr {
        width: 50%;
      }

      td {
        display: block;
        padding: 0.5rem 0.95rem;

        &::before {
          display: block;
          margin-bottom: 5px;
        }
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  .advanced-table {
    thead tr {
      display: block;
      margin-bottom: 1.2rem;
    }

    td {
      display: flex;
      align-items: center;
      padding: 0.4rem 0.95rem;

      &:first-child {
        padding-top: 0.8rem;
      }

      &:last-child {
        padding-bottom: 0.55rem;
      }

      &::before {
        flex: 1;
        margin-bottom: unset;
      }

      .col-with-image {
        flex-direction: row-reverse;

        img {
          margin-right: 0;
          margin-left: 0.6rem;
        }
      }
    }
  }
}

@media (max-width: 425px) {
  .advanced-table {
    td {
      display: block;

      &::before {
        width: 100%;
        display: block;
        margin-bottom: 5px;
      }

      .col-with-image {
        flex-direction: row;

        img {
          margin-left: 0;
          margin-right: 0.6rem;
        }
      }
    }
  }
}
