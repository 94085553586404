.tooltip {
  &-inner {
    box-shadow: 0px 2px 7px 0px #0000001f;
    color: $white;
    font-weight: 400;
    line-height: 17px;
    font-size: 0.75rem;
    padding: 10px;
  }
}
