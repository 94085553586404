#popover-actions {
  border: none;
  outline: none;
  box-shadow: 0px 2px 12px 0px #0000000a;
  overflow: hidden;
  z-index: 1;

  .popover-body {
    border: none;
    padding: 0;
    margin: 0;
    outline: none;
    box-shadow: none;
  }

  .popover-arrow {
    display: none;
  }

  .action-item {
    min-width: 11.25em;
    padding: 0.5em;
    display: flex;
    align-items: center;
    gap: 0.5em;
    transition: 0.1s ease;
    color: $black;
    -webkit-transition: 0.1s ease;
    -moz-transition: 0.1s ease;
    -ms-transition: 0.1s ease;
    -o-transition: 0.1s ease;

    &:hover {
      background-color: $neutral-2;
    }

    &--remove {
      color: $primary;
    }
  }
}
