.w-20 {
  width: 20% !important;
}

.w-26 {
  width: 26% !important;
}

.w-35 {
  width: 35% !important;
}

.w-70 {
  width: 70% !important;
}

.w-85 {
  width: 85% !important;
}

.w-30 {
  width: 30% !important;
}

.w-90 {
  width: 90% !important;
}

.w-140 {
  width: 140% !important;
}

.w-45 {
  width: 45% !important;
}
