.custom-header-toolbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 28px;
  color: #404040;

  &__navigation {
    display: flex;
    align-items: center;
    gap: 4px;

    p {
      font-size: 1.125rem;
    }

    button {
      background: none;
      outline: none;
      border: none;
      width: 30px;
      height: 3px;
      display: grid;
      place-content: center;

      i {
        transition: 0.2s ease;
        -webkit-transition: 0.2s ease;
        -moz-transition: 0.2s ease;
        -ms-transition: 0.2s ease;
        -o-transition: 0.2s ease;
      }

      &:hover i {
        color: $primary-red;
      }
    }
  }
}

.custom-more-link {
  color: $primary-blue;
  font-size: 0.8rem;
  margin-top: 0.2em !important;
  float: right !important;
}
