.table-search {
  display: flex;
  height: 100%;
  min-height: 52px;
  width: 300px;

  > div {
    width: 100%;
    min-height: 52px;
  }

  > div,
  input {
    height: 100%;
    min-height: 52px;
  }

  button {
    height: 100%;
    width: 50px;
    min-height: 52px;
    justify-content: center;
    align-items: center;
  }
}

.table-new {
  .btn {
    width: 100%;
    margin-bottom: 0.75rem;
  }
}

.table-card-header {
  border-bottom: none;
}

.table-filter-section {
  background-color: $primary;

  hr,
  .form-label {
    color: white;
  }
}

.table-th {
  padding: 14px;
  position: relative;
  font-weight: normal;
  border: none;
  color: $black;
  border: none;

  &.sortable {
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  i {
    margin-left: 8px;
    font-size: 18px;
    opacity: 0.5;

    &:not(.mdi-arrow-up-down) {
      opacity: 1;
    }
  }
}

.table-pagination-details > div {
  margin-bottom: 0.9rem;
}

.table-pagination-numbers {
  display: flex;
  align-items: center;
}

.pagination {
  display: flex;
  gap: 12px;

  .page-item {
    .page-link {
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $black;
      font-size: 15px;
      padding: 0;
      border-color: $border-color;
      border-radius: 5px;
    }

    &.active {
      .page-link {
        color: $white;
      }
    }
  }
}

@include media-breakpoint-up(sm) {
  .table-new {
    display: flex;
    justify-content: flex-end;

    .btn {
      width: unset;
      margin-bottom: unset;
    }
  }

  .table-pagination-details {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .table-pagination-numbers {
    justify-content: center;
    align-items: center;
  }
  .search-icon {
    border: none;
    height: 38px;
    width: 40px;
    background-color: #dddce1;
    border-radius: 0 5px 5px 0;
    color: #8f8f92;
    margin-left: -5px;
  }
}

@include media-breakpoint-up(lg) {
  .table-pagination-details {
    justify-content: start;

    > div {
      margin-bottom: 0;
    }
  }

  .table-pagination-numbers {
    justify-content: end;
  }
}

.search-icon {
  border: none;
  height: 38px;
  width: 40px;
  background-color: #dddce1;
  border-radius: 0 5px 5px 0;
  color: #8f8f92;
}
