.highlight-image {
  position: relative;

  .image {
    width: 100%;
    height: 365px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    object-fit: cover;
    object-position: center;
  }
}
