.description {
  font-size: 15px;

  &__label {
    color: #8f8f92;
    margin-bottom: 4px !important;
  }

  &__label,
  &__info {
    line-height: 23.25px;
  }
}

@include media-breakpoint-up(sm) {
  .description__label {
    margin-bottom: 8px !important;
  }
}
