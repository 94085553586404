//
// forms.scss
//

// Remove count arrow on input type number

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: unset;
  display: none;
  opacity: 1;
}

/* Firefox */
input[type='number']::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  -moz-appearance: textfield;
  appearance: unset;
}

textarea::-webkit-scrollbar {
  width: 5px;
}

textarea::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

textarea::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

// Form-control light
.form-control-light {
  background-color: #{map-get($grays, '100')} !important;
  border-color: #{map-get($grays, '100')} !important;
}

// Form elements (Color and Range)
input.form-control[type='color'],
input.form-control[type='range'] {
  min-height: 39px;
}

// Custom select
.custom-select.is-invalid,
.form-control.is-invalid,
.custom-select:invalid,
.form-control:invalid,
.custom-select.is-valid,
.form-control.is-valid,
.custom-select:valid,
.form-control:valid {
  &:focus {
    box-shadow: none !important;
  }
}

select.form-control {
  &:not([size]):not([multiple]) {
    height: $input-height;
  }
}

select.form-control-sm {
  &:not([size]):not([multiple]) {
    height: $input-height-sm;
  }
}

// Show Password
.password-eye {
  &:before {
    font-family: 'Material Design Icons';
    content: '\F06D0';
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    vertical-align: middle;
    line-height: 1.2;
    font-size: 16px;
  }
}

.show-password {
  .password-eye {
    &:before {
      content: '\F06D1';
    }
  }
}

.custom-date {
  position: relative;

  &-icon {
    position: absolute;
    top: 50%;
    right: 1px;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    height: calc(100% - 2px);
    width: 44px;
    background-color: #dddce1;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0rem 0.2rem 0.2rem 0;
    -webkit-border-radius: 0rem 0.2rem 0.2rem 0;
    -moz-border-radius: 0rem 0.2rem 0.2rem 0;
    -ms-border-radius: 0rem 0.2rem 0.2rem 0;
    -o-border-radius: 0rem 0.2rem 0.2rem 0;
    cursor: pointer;
  }
}

// Dark mode
body[data-layout-color='dark'] {
  .form-control-light {
    background-color: #{map-get($dark-grays, '100')} !important;
    border-color: #{map-get($dark-grays, '100')} !important;
  }
}

.react-select {
  &.is-invalid > div {
    border-color: $danger;
  }
}

@include media-breakpoint-up(md) {
  .select-container {
    width: 50%;
  }
}
