.tittle-overview {
  font-size: 24px;
  font-weight: 700;
  line-height: 125%;
  color: $neutral-7;
  margin: 0 !important;
}

.price-overview {
  font-weight: 600;
  color: $black;
  font-size: 15px;
  background-color: $primary;
  line-height: 155%;
}

.text-overview {
  font-size: 15px;
  font-style: normal;
  font-weight: 700;
  line-height: 155%;
  color: $black;
}

.status-overview {
  position: absolute;
  top: 20px;
  left: 20px;
  display: flex;
  justify-content: space-between;
  width: 95%;
}

.total-icon {
  color: #0d0d0e;
  background-color: white;
  font-size: 14px;
  height: 18px;
  width: 18px;
  text-align: center;
  line-height: 16px;
  border-radius: 100%;
  display: inline-block;
}

.accordion-button {
  padding: 10px 15px 10px 15px;
  background-color: #f7f7f7;
  border-bottom: solid;
  border-width: 1px;
  border-color: #dee2e6;
}

.add-link {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0;
  font-weight: 500 !important;
  border: none !important;
  background-color: white;
}

.label-task {
  font-size: 15px !important;
  font-weight: 400 !important;
  line-height: 155%;
  color: #8f8f92;
}

.task-item {
  font-size: 15px;
  font-weight: 500;
  line-height: 155%;
  color: #0d0d0e;
}

.copy-function {
  font-size: 18px;
  cursor: pointer;

  &:hover {
    color: $primary;
  }
}

.img-overview {
  height: 250px;
  width: 100%;
  object-fit: cover;
}

.income-status {
  margin: 0 !important;
  position: absolute;
  bottom: 24px;
  right: 24px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 8px;
  padding-right: 10px;
  color: $neutral-7;
  border-radius: 2px;
}

.payment-status {
  margin: 0 !important;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 8px;
  padding-right: 10px;
  color: $neutral-7;
  border-radius: 2px;
}

.container-overview {
  height: 100vh;
}

.challenge-overview {
  position: relative;
}
