//
// icons.scss
//

@import 'icons/unicons';
@import 'icons/dripicons';
@import 'icons/materialdesignicons';
@import 'icons/icomoon';

.mdi-25px.mdi-set,
.mdi-25px.mdi:before {
  font-size: 25px;
}
