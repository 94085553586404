.card-first-responder {
  &__title {
    color: #8f8f92;
    font-size: 15px;
  }
}

.button-end {
  width: unset;
  border: none;
  outline: inherit;
  background-color: unset;
  padding: 4.5px 12px;
  font-weight: 600;
  font-size: 15px;
  position: absolute;
  right: 24px;
  top: 24px;
}
