@font-face {
  font-family: 'icomoon';
  src: url('../fonts/icomoon.eot');
  src: url('../fonts/icomoon.eot') format('embedded-opentype'),
    url('../fonts/icomoon.woff') format('woff'), url('../fonts/icomoon.ttf') format('truetype'),
    url('../fonts/icomoon.svg') format('svg');
  font-weight: normal;

  font-style: normal;
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  text-rendering: auto;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  vertical-align: middle;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-document-content:before {
  content: '\e989';
}
.icon-Frame:before {
  content: '\e979';
}
.icon-Small:before {
  content: '\e97f';
}
.icon-activity:before {
  content: '\e94d';
}
.icon-adventure:before {
  content: '\e901';
}
.icon-almonds:before {
  content: '\e94e';
}
.icon-arrow_to_left:before {
  content: '\e902';
}
.icon-arrow_to_right:before {
  content: '\e903';
}
.icon-avocado:before {
  content: '\e94f';
}
.icon-biometric-1:before {
  content: '\e984';
}
.icon-block:before {
  content: '\e950';
}
.icon-buildings:before {
  content: '\e951';
}
.icon-burger:before {
  content: '\e95a';
}
.icon-burger-menu:before {
  content: '\e904';
}
.icon-calendar:before {
  content: '\e906';
}
.icon-calendar-warning:before {
  content: '\e905';
}
.icon-call:before {
  content: '\e907';
}
.icon-cancle-Stroke:before {
  content: '\e973';
}
.icon-cat:before {
  content: '\e952';
}
.icon-check:before {
  content: '\e908';
}
.icon-check-fill:before {
  content: '\e974';
}
.icon-check-radio:before {
  content: '\e953';
}
.icon-chevron-double-right:before {
  content: '\e909';
}
.icon-chevron-down:before {
  content: '\e90a';
}
.icon-chevron-left:before {
  content: '\e90b';
}
.icon-chevron-right:before {
  content: '\e90c';
}
.icon-chevron-top:before {
  content: '\e94c';
}
.icon-chicken-legs:before {
  content: '\e954';
}
.icon-clock:before {
  content: '\e90d';
}
.icon-close:before {
  content: '\e90e';
}
.icon-cloud:before {
  content: '\e976';
}
.icon-connect:before {
  content: '\e90f';
}
.icon-cookies:before {
  content: '\e966';
}
.icon-copy:before {
  content: '\e910';
}
.icon-credit-card:before {
  content: '\e912';
}
.icon-credit-card-check:before {
  content: '\e911';
}
.icon-cup:before {
  content: '\e955';
}
.icon-cycling:before {
  content: '\e913';
}
.icon-diary:before {
  content: '\e967';
}
.icon-document:before {
  content: '\e914';
}
.icon-dot:before {
  content: '\e915';
}
.icon-double-check:before {
  content: '\e916';
}
.icon-dumbbell:before {
  content: '\e956';
}
.icon-dumbell:before {
  content: '\e917';
}
.icon-edit:before {
  content: '\e985';
}
.icon-email:before {
  content: '\e91a';
}
.icon-expand:before {
  content: '\e977';
}
.icon-female:before {
  content: '\e91b';
}
.icon-file-cloud:before {
  content: '\e986';
}
.icon-file-download:before {
  content: '\e988';
}
.icon-filter:before {
  content: '\e91c';
}
.icon-fire:before {
  content: '\e91d';
}
.icon-fish:before {
  content: '\e957';
}
.icon-fistbump:before {
  content: '\e958';
}
.icon-flag:before {
  content: '\e978';
}
.icon-globe:before {
  content: '\e91e';
}
.icon-great:before {
  content: '\e91f';
}
.icon-growth:before {
  content: '\e959';
}
.icon-heart-rate:before {
  content: '\e920';
}
.icon-height:before {
  content: '\e922';
}
.icon-height-1:before {
  content: '\e921';
}
.icon-help-support:before {
  content: '\e923';
}
.icon-home:before {
  content: '\e924';
}
.icon-home-open:before {
  content: '\e95b';
}
.icon-image:before {
  content: '\e925';
}
.icon-info-circle:before {
  content: '\e95c';
}
.icon-info-circle1:before {
  content: '\e926';
}
.icon-information:before {
  content: '\e97a';
}
.icon-key:before {
  content: '\e927';
}
.icon-link:before {
  content: '\e928';
}
.icon-location:before {
  content: '\e929';
}
.icon-login:before {
  content: '\e97b';
}
.icon-logout:before {
  content: '\e92a';
}
.icon-male:before {
  content: '\e92b';
}
.icon-medal:before {
  content: '\e92c';
}
.icon-meditation:before {
  content: '\e92d';
}
.icon-menu:before {
  content: '\e95d';
}
.icon-menu-dots:before {
  content: '\e975';
}
.icon-min:before {
  content: '\e92e';
}
.icon-moon:before {
  content: '\e92f';
}
.icon-more:before {
  content: '\e930';
}
.icon-muscle:before {
  content: '\e931';
}
.icon-notebook:before {
  content: '\e980';
}
.icon-notifications:before {
  content: '\e932';
}
.icon-other-sport:before {
  content: '\e933';
}
.icon-pause:before {
  content: '\e95e';
}
.icon-people:before {
  content: '\e934';
}
.icon-personal-information:before {
  content: '\e968';
}
.icon-personal-information1:before {
  content: '\e97c';
}
.icon-pin:before {
  content: '\e97d';
}
.icon-pizza:before {
  content: '\e95f';
}
.icon-play:before {
  content: '\e935';
}
.icon-plus:before {
  content: '\e936';
}
.icon-preview-off:before {
  content: '\e937';
}
.icon-preview-on:before {
  content: '\e938';
}
.icon-real-text:before {
  content: '\e939';
}
.icon-receipt:before {
  content: '\e93a';
}
.icon-repeat:before {
  content: '\e93f';
}
.icon-reset:before {
  content: '\e93b';
}
.icon-robot:before {
  content: '\e93c';
}
.icon-rocket:before {
  content: '\e969';
}
.icon-ruler:before {
  content: '\e960';
}
.icon-running:before {
  content: '\e93d';
}
.icon-search:before {
  content: '\e93e';
}
.icon-sets:before {
  content: '\e96c';
}
.icon-setting:before {
  content: '\e940';
}
.icon-share:before {
  content: '\e941';
}
.icon-shell:before {
  content: '\e961';
}
.icon-smile:before {
  content: '\e942';
}
.icon-sort:before {
  content: '\e943';
}
.icon-sparkles:before {
  content: '\e96a';
}
.icon-star:before {
  content: '\e962';
}
.icon-step:before {
  content: '\e944';
}
.icon-stopwatch:before {
  content: '\e981';
}
.icon-streght:before {
  content: '\e945';
}
.icon-swimming:before {
  content: '\e946';
}
.icon-target:before {
  content: '\e96d';
}
.icon-track:before {
  content: '\e972';
}
.icon-trash:before {
  content: '\e947';
}
.icon-tree:before {
  content: '\e963';
}
.icon-uniE918:before {
  content: '\e918';
}
.icon-uniE919:before {
  content: '\e919';
}
.icon-uniE96E:before {
  content: '\e96e';
}
.icon-uniE96F:before {
  content: '\e96f';
}
.icon-uniE970:before {
  content: '\e970';
}
.icon-uniE971:before {
  content: '\e971';
}
.icon-upload:before {
  content: '\e900';
}
.icon-upload-new:before {
  content: '\e987';
}
.icon-user:before {
  content: '\e949';
}
.icon-user-check:before {
  content: '\e948';
}
.icon-user-lock:before {
  content: '\e96b';
}
.icon-verified:before {
  content: '\e94a';
}
.icon-virtual:before {
  content: '\e97e';
}
.icon-wallet:before {
  content: '\e94b';
}
.icon-weight:before {
  content: '\e982';
}
.icon-whatsapp:before {
  content: '\e983';
}
.icon-wheat:before {
  content: '\e964';
}
.icon-winter-sport:before {
  content: '\e965';
}
