.event-brief-items {
  display: flex;
  margin-top: 32px;
}

.event-brief-item {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 150px;
  width: fit-content;
  max-width: 450px;
  padding: 0 12px;

  &__label {
    color: $neutral-5;
    margin-bottom: 8px;
  }

  &__content {
    display: flex;
    align-items: flex-start;
    gap: 8px;

    i {
      margin-top: -5px;
    }

    i + div,
    i + p {
      margin-top: 3px !important;
    }
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 1px;
    background-color: #e0dfe4;
  }

  &:first-child {
    padding-left: 0;
  }

  &:last-child::after {
    display: none;
  }
}
