// Button shadow

@mixin button-shadow($color) {
  box-shadow: $btn-box-shadow rgba($color, 0.5);
}

.button-filter {
  font-weight: 700;
}

.icons-filter {
  line-height: 16px;
  font-size: 16px;
  display: inline-block;
  text-align: center;
  margin-top: 3.5px;
}

.back-button {
  border: solid;
  border-radius: 4px;
  border-width: 1px;

  &:hover {
    background-color: $neutral-2;
  }
}

.btn:disabled {
  background-color: $black;
  border-color: $black;
}

.btn-unstyled {
  border: none !important;
  outline: none !important;
  background-color: transparent;
  box-shadow: none;

  &:hover,
  &:target,
  &:active,
  &:focus {
    border: none !important;
    outline: none !important;
    background-color: transparent;
    box-shadow: none !important;
  }
}
