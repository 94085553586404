//
// avatar.scss (avatar thumbnail sizes)
//

.avatar-xs {
  height: 1.5rem;
  width: 1.5rem;
}

.avatar-sm {
  height: 44px;
  width: 44px;

  p {
    font-size: 24px;
    font-weight: 700 !important;
    line-height: 36px;
    margin: 0;
  }
}

.avatar-md {
  height: 4.5rem;
  width: 4.5rem;
}

.avatar-lg {
  height: 6rem;
  width: 6rem;

  p {
    font-size: 48px;
    font-weight: 700 !important;
    line-height: 64px;
    margin: 0;
  }
}

.avatar-xl {
  height: 7.5rem;
  width: 7.5rem;
}

.avatar-title {
  align-items: center;
  background-color: $primary;
  color: $white;
  display: flex;
  font-weight: $font-weight-semibold;
  height: 100%;
  justify-content: center;
  width: 100%;
}
