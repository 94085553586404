.wysiwyg-editor {
  min-height: 150px;
  padding: 0.25rem 0.9rem;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  -webkit-border-radius: 0.25rem;
  -moz-border-radius: 0.25rem;
  -ms-border-radius: 0.25rem;
  -o-border-radius: 0.25rem;

  &.is-invalid {
    border-color: red;
  }

  iframe {
    width: 100%;
    height: 365px;
  }

  figure img {
    width: 100% !important;
  }
}

.preview_wyswyg_content {
  iframe,
  img {
    width: 100% !important;
    height: auto !important;
    object-fit: cover;
  }

  iframe {
    height: 365px !important;
  }
}
