.accordion-header {
  margin-top: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
}

.accordion-button:not(.collapsed) {
  color: $black !important;
  background-color: white;
}

.accordion-button:focus {
  outline: none !important;
  box-shadow: none !important;
}

.accordion-item-header {
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
  -moz-transition: 0.3s ease;
  -ms-transition: 0.3s ease;
  -o-transition: 0.3s ease;
}

.accordion-icon {
  font-size: 20px;
  background-color: #f2f1f6;
  height: 20px;
  width: 20px;
  text-align: center;
  line-height: 20px;
  border-radius: 100%;
  display: inline-block;
  align-items: center;
}

.accordion-order-icon {
  width: 24px;
  height: 24px;
  display: grid;
  place-items: center;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.custom-accordion-tutorial-item {
  margin: 0;
  padding: 0;
  border: none !important;
  background-color: transparent;
  width: 100%;
}

.custom-accordion-tutorial-header {
  button {
    padding: 0;
    margin: 0;
    background-color: transparent;
    border: none !important;
    box-shadow: none !important;
  }

  ::after {
    display: none;
  }
}

.accordion-item:last-of-type .side-menu-item {
  margin-bottom: 0 !important;
}
