//
// dropzone.scss
//
.dropzone {
  border: 2px dashed $input-border-color;
  background: $input-bg;
  border-radius: 6px;
  cursor: pointer;
  .dz-message {
    text-align: center;
    padding: 3rem 0;
  }

  &.dz-started {
    .dz-message {
      display: none;
    }
  }
}

@include media-breakpoint-down(xl) {
  .dropzone {
    height: 200px;
  }
}

@include media-breakpoint-down(md) {
  .dropzone {
    height: 180px;
  }
}
