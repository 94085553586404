/*
Template Name: Hyper - Responsive Bootstrap 5 Admin Dashboard
Version: 3.4.0
Author: CoderThemes
Email: support@coderthemes.com
File: Main Css File
*/

//Core files

@import 'node_modules/bootstrap/scss/functions';
@import 'node_modules/bootstrap/scss/variables';
@import 'config/variables';

@import 'config/light-mode';
@import 'config/dark-mode';
@import 'config/custom-variables';

@import 'node_modules/bootstrap/scss/bootstrap';

// Structure
@import 'custom/structure/general';
@import 'custom/structure/left-menu';
@import 'custom/structure/topbar';
@import 'custom/structure/page-head';

//Fonts
@import 'custom/fonts/accord';

//Components
@import 'custom/components/mixins';
@import 'custom/components/alert';
@import 'custom/components/breadcrumb';
@import 'custom/components/card';
@import 'custom/components/dropdown';
@import 'custom/components/forms';
@import 'custom/components/reboot';
@import 'custom/components/tables';
@import 'custom/components/type';
@import 'custom/components/utilities';
@import 'custom/components/widgets';
@import 'custom/components/avatar';
@import 'custom/components/badge';
@import 'custom/components/detail-card-header';
@import 'custom/components/profile-generator';
@import 'custom/components/advanced-table-header';
@import 'custom/components/advanced-table';
@import 'custom/components/basic-table';
@import 'custom/components/preloader';
@import 'custom/components/toast';
@import './custom/components/rounded-image';
@import './custom/components/accordion';
@import './custom/components/highlight';
@import './custom/components/category-label';
@import './custom/components/event-brief-item';
@import './custom/components/ticket-item';
@import './custom/components/copyable-link';
@import './custom/components/profile-badge';
@import './custom/components/media';
@import './custom/components/image';
@import './custom/components/location-search-input';
@import './custom/components/description';
@import './custom/components/preview-details';
@import './custom/components/modal';
@import './custom/components/event-calender';
@import './custom/components/phone-number';
@import './custom/components/date-picker';
@import './custom/components/youtube-embeded';
@import './custom/components/tooltip';
@import './custom/components/list-group';
@import './custom/components/tab-menu';
@import './custom/components/wyswyg';
@import './custom/components/progress';

// Pages
@import 'custom/pages/authentication';
@import 'custom/pages/challenges';
@import 'custom/pages/preview';
@import 'custom/pages/scales';
@import 'custom/pages/overview';
@import 'custom/pages/profile';
@import 'custom/pages/payments';
@import 'custom/pages/welcome';
@import 'custom/pages/content-management';

// Plugins
@import 'custom/plugins/simplebar';
@import 'custom/plugins/react-select';
@import 'custom/plugins/dropzone';
@import 'custom/plugins/splide';
@import 'custom/plugins/full-calendar';
