.progress {
  position: relative;
  width: 100%;
  height: 4px;
  border-radius: 4px;
  background-color: $neutral-2;

  .progress-bar,
  .progress-bar-full {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    background-color: #f7b255;
    transition: width 0.3s ease;
  }

  .progress-bar-full {
    background-color: #017e78 !important;
  }
}
