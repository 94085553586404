.location-search-input {
  width: 100%;
  position: relative;

  &__dropdown {
    width: 100%;

    .dropdown-menu {
      width: 100%;

      .dropdown-item {
        text-wrap: pretty;
        font-weight: 400;
      }
    }
  }
}
