.container-welcome {
  position: absolute;
  width: 100%;
  height: 100% !important;
  background-color: white;
  display: flex;
  justify-content: center;
  overflow-y: auto;

  .card-type {
    border: 1px solid;
    border-radius: 8px;
    width: max-content;
    cursor: pointer;

    &:hover {
      background-color: #f2f1f6;
    }

    .card-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 32px;
      max-width: 350px;
      min-width: 350px;
    }

    .image-organizer {
      width: 200px;
      height: 165px;
    }
    .card-title {
      font-size: 18px;
      font-weight: 700;
      text-align: center;
      color: black;
      margin-top: 24px;
    }

    .card-description {
      font-size: 12px;
      font-weight: 400;
      line-height: 18px;
      text-align: center;
      color: black;
    }
  }

  .list-organizer {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    margin-top: 74px;
  }

  .content-welcome {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .title-welcome {
      font-size: 48px;
      font-weight: 700;
      line-height: 40.32px;
      letter-spacing: -1px;
      text-align: center;
      color: black;
    }

    .description-welcome {
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 155%;
      color: black;
      text-align: center;
      max-width: 500px;
    }
  }
}

.container-type-organizer {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  overflow-y: auto;

  .card-form {
    padding: 24px;
    border-radius: 8px;
    width: 100%;
    border: 1px solid #dddce1;
  }
}
