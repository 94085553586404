.ticket-items {
  display: flex;
  gap: 24px;
}

.ticket-item {
  height: 185px;
  width: 300px;
  color: $white;
  padding: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;

  &__title {
    font-size: 16px;
    font-weight: 700;
  }

  &__label {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 12px;
  }

  &__pricing {
    font-size: 28px;
    font-weight: 700;
  }
}
