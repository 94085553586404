@font-face {
  font-family: 'AccordAlternate';
  src: url('../fonts/AccordAlternate-ExtraLight.woff2') format('woff2'),
    url('../fonts/AccordAlternate-ExtraLight.woff') format('woff'),
    url('../fonts/AccordAlternate-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AccordAlternate';
  src: url('../fonts/AccordAlternate-ExtraBold.woff2') format('woff2'),
    url('../fonts/AccordAlternate-ExtraBold.woff') format('woff'),
    url('../fonts/AccordAlternate-ExtraBold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AccordAlternate';
  src: url('../fonts/AccordAlternate-Bold.woff2') format('woff2'),
    url('../fonts/AccordAlternate-Bold.woff') format('woff'),
    url('../fonts/AccordAlternate-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AccordAlternate';
  src: url('../fonts/AccordAlternate-Light.woff2') format('woff2'),
    url('../fonts/AccordAlternate-Light.woff') format('woff'),
    url('../fonts/AccordAlternate-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AccordAlternate';
  src: url('../fonts/AccordAlternate-Medium.woff2') format('woff2'),
    url('../fonts/AccordAlternate-Medium.woff') format('woff'),
    url('../fonts/AccordAlternate-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'AccordAlternate';
  src: url('../fonts/AccordAlternate.woff2') format('woff2'),
    url('../fonts/AccordAlternate.woff') format('woff'),
    url('../fonts/AccordAlternate.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
