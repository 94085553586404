.profile-badge {
  display: flex;
  align-items: center;
  gap: 16px;

  &__info {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  &__name {
    display: block;
    color: #161620 !important;
    font-size: 15px;
    font-weight: 600;
    line-height: 135%;
  }

  &__role {
    display: block;
    color: $neutral-4;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 155%;
  }
}
