.challenge-preview {
  position: relative;
  margin: 20px 20px 0 20px;

  .header-bg {
    position: absolute;
    top: 0;
    left: 0;
    height: 325px;
    width: 100%;
    background-color: $neutral-2;
  }

  .content {
    position: relative;
    padding: 10px 0;
    width: 90%;
    margin: auto;

    .header {
      padding-top: 40px;
    }
  }
}
