.side-menu-item {
  padding: 16px;
  transition: 0.1s ease-in-out;
  -webkit-transition: 0.1s ease-in-out;
  -moz-transition: 0.1s ease-in-out;
  -ms-transition: 0.1s ease-in-out;
  -o-transition: 0.1s ease-in-out;
}

.side-menu-hover {
  &:hover {
    background-color: #efefef;
  }
}
